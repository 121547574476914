.deletable-row-disabled {
  cursor: not-allowed;
  position: relative;
}

.deletable-row-disabled *,
.deletable-row-disabled *:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  pointer-events: none;
  cursor: not-allowed;
}
